// from yarn
import "@babel/polyfill";
// import 'jquery';
import Turbolinks from 'turbolinks';
import Rails from 'rails-ujs';
import * as ActiveStorage from 'activestorage';
// import 'tether'
import 'moment'
import 'accounting'
import linkifyJq from 'linkify-jquery';
linkifyJq($, document);
import 'jquery-ui-sortable'
import 'pluralize'
import 'handlebars'
import 'chart.js'
import 'jquery.sticky'
import 'bowser'
import modernizr from 'modernizr';

// from vendor/assets/javascripts
import 'select2.min'
import 'readmore.min'
import 'multi.min'
import ahoy from 'ahoy'
// import 'bootstrap4/bootstrap.min'

// from app/javascript/src
import '../src/acknowledgement_dropdowns';
import '../src/acknowledgement_sidebar';
import '../src/activity_feed';
import AddressFormFields from '../src/address_form_fields';
import AdvancedSearch from '../src/advanced_search';
import Alert from '../src/alert';
import Attachments from '../src/attachments';
import '../src/bee';
import '../src/bowser';
import '../src/campaign_dashboard';
import '../src/character_counter';
import '../src/complete_task';
import '../src/contact_list';
import '../src/content_sections';
import '../src/copy_to_clipboard';
import '../src/custom_date_range_modal';
import '../src/custom_form';
import '../src/dashboard';
import DateTimePicker from '../src/datetimepicker';
import '../src/date_range_modal';
import '../src/donation_form';
import DonationsChart from '../src/donations_chart';
import DoughnutChart from '../src/doughnut_chart';
import '../src/dropdown_menu';
import '../src/duplicate_form_modal';
import '../src/email_acknowledgement_sidebar';
import '../src/email_creator_layout_form';
import '../src/email_creator_layout_tabs';
import '../src/email_form';
import '../src/embedded_form_modal';
import '../src/form_field_form';
import '../src/highlight_content';
import '../src/household_transactions';
import '../src/iframe_height';
import '../src/iframe_preview';
import '../src/iframe_refresh';
import '../src/insert_links';
import '../src/keybindings';
import Loader from '../src/loader';
import '../src/mailing_creator_setup_form';
import '../src/mailing_form';
import '../src/miles_from_zip_code_modal';
import '../src/modals';
import '../src/my_tools';
import '../src/onboarding_build_form';
import '../src/onboarding_preview_iframe';
import '../src/onboarding_save_and_exit';
import '../src/pledge_form';
import '../src/public_pledge_form';
import '../src/quick_search_modal';
import ReadMore from '../src/read_more';
import '../src/redactor';
import '../src/refresh_iframe';
import '../src/refresh_links';
import '../src/save_and_add_another';
import '../src/select2';
import '../src/share_form';
import '../src/show_hide';
import '../src/side_nav';
import '../src/slats';
import '../src/soft_credits_form';
import '../src/sticky';
import TaskForm from '../src/task_form';
import '../src/task_list';
import '../src/tasks_nav_item';
import '../src/text_message_form';
import '../src/tooltips';
import '../src/transactions_chart';
import '../src/auto_complete';
import '../src/user_form';
import UserProfileGroups from '../src/user_profile_groups';
import '../src/user_profile';
import UserSelect from '../src/user_select';
import '../src/video_acknowledgement_form';
import '../src/video_creator_introduction_form';
import '../src/video_creator_setup_form';
import '../src/video_recording';
import '../src/user_email_form';
import '../src/sys_functionality_indicator';
import '../src/video_share_modals';
import '../src/email_gallery';
import '../src/video_donate_modal';

// It's important that this happens before Rails.start() so don't
// lose click tracking of remote: true links.
ahoy.trackClicks();

Turbolinks.start();
Rails.start();
ActiveStorage.start();

// these are used in js.erb views, so they need to be globally accessible
window.App = {
  AddressFormFields: AddressFormFields,
  AdvancedSearch: AdvancedSearch,
  Alert: Alert,
  Attachments: Attachments,
  DateTimePicker: DateTimePicker,
  DonationsChart: DonationsChart,
  DoughnutChart: DoughnutChart,
  Loader: Loader,
  ReadMore: ReadMore,
  TaskForm: TaskForm,
  UserProfileGroups: UserProfileGroups,
  UserSelect: UserSelect
};

// for react components
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

import "controllers"
